<template>
  <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">

      <vx-card title="แก้ไข ลำดับ MVP">
        <vs-td >
          <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border" size="small" icon-pack="feather" icon="icon-edit" class="mr-2 mb-4"
          @click="clearData() ,popupInfo('add')">เพิ่มอันดับ MVP</vs-button>
      </vs-td>
      <VueDatePicker v-model="date"></VueDatePicker>
      <vs-table :data="infoMvp">
        <template slot="thead">
          <vs-th>
            username
          </vs-th>
          <vs-th>
            ชื่อ-นามสกุล
          </vs-th>
          <vs-th>
            ยอดฝาก
          </vs-th>
          <vs-th>
            ยอดถอน
          </vs-th>
          <vs-th>
            PNL
          </vs-th>
          <vs-th>
            ยอดเทริน
          </vs-th>
          <vs-th>
            รอบกิจกรรม
          </vs-th>
          <vs-th>
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr  :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="tr.username" >
             {{ tr.username }}
            </vs-td>
            <vs-td :data="tr.fullname" >
             {{ tr.fullname }}
            </vs-td>
            <vs-td :data="tr.topup" >
             {{ tr.topup }}
            </vs-td>
            <vs-td :data="tr.withdraw" >
             {{ tr.withdraw }}
            </vs-td>
            <vs-td :data="tr.pnl" >
             {{ tr.pnl }}
            </vs-td>
            <vs-td :data="tr.turn" >
             {{ tr.turn }}
            </vs-td>
            <vs-td :data="tr.period">
             {{ tr.period }}
            </vs-td>
            <vs-td>
              <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true" type="border" size="small" icon-pack="feather" icon="icon-edit" class="mr-2"
                @click="popupInfo('edit', tr)">แก้ไข</vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vs-popup classContent="popup-example" :title="`${this.infopopup.type} MVP`" :active.sync="showpopup">
      <div v-if="this.infopopup.type == 'add'" >
          <small class="text-danger">***หมาเหตุ กรอกชื่อนามสกุลเต็มได้เลยระบบจะตัดและจัดเรียงให้ท่านเอง ***</small>
          <vs-input class="w-full" label-placeholder="ชื่อ" v-model="infopopup.name" type = "text" name="user" disabled/>
          <br>
          <vs-input class="w-full" label-placeholder="นามสกุล" v-model="infopopup.surname" type = "text" name="user" disabled/>
          <br>

    </div>
      <vs-input class="w-full" label-placeholder="username" v-model="infopopup.username" type = "text" name="user" />
      <br>
      <vs-input class="w-full" label-placeholder="ยอดฝาก" v-model="infopopup.deposit" type = "number" name="deposit"/>
      <br>
      <vs-input class="w-full" label-placeholder="ยอดถอน" v-model="infopopup.withdraw" type = "number" name="withdraw" />
      <br>
      <vs-input class="w-full" label-placeholder="ยอดเทริน" v-model="infopopup.turn" type = "number" name="turn" />
      <br>
      <flat-pickr v-if="this.infopopup.type == 'add'" :config="configDateTimePicker"  class="w-full mt-2 mb-2" placeholder="โปรดเลือกเดือน" type = "text" v-model="infopopup.month" />
       <vs-button :disabled="$store.state.AppActiveUser.permissions.setting.action ? false : true"  size="small" icon-pack="feather" icon="icon-edit" class="mr-2 mt-4"
                @click="confirm_edit(infopopup.type)">ยืนยัน</vs-button>
    </vs-popup>
  </div>
</template>


<script>
import axios from '../../axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import monthSelect from 'flatpickr/dist/plugins/monthSelect'

export default {
  components: {
    flatPickr
  },
  data () {
    return {
      memberData: '',
      searchmember: '',
      infoMvp: '',
      showpopup: false,
      infopopup: {
        id: '',
        username: '',
        deposit: '',
        withdraw: '',
        turn: '',
        type : '',
        name : '',
        surname : '',
        month: ''
      },
      date: '',
      configDateTimePicker: {
        dateFormat: 'Y-m-d H:i:s',
        plugins: [
          new monthSelect({
            dateFormat: 'Y-m-m', //defaults to "F Y",
            theme: 'dark'
          })
        ]
      },
      error : ''
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  watch: {
    'infopopup.username' () {
      this.searchuser()
    }
  },
  mounted () {
    this.callinfo()
    this.searchuser()
  },
  methods: {
    searchuser () {
      axios
        .get(`member/searchmember/${this.infopopup.username}`)
        .then((response) => {
          // Assuming response.data is an object containing user data
          const userData = response.data[0]
          this.infopopup.name = userData.member_name
          this.infopopup.surname = userData.member_surname
        })

    },
    callinfo () {
      axios
        .get('/mvp/getmvp')
        .then(response => (this.infoMvp = response.data))
    },
    currency (amount) {
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        return `${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    },
    popupInfo (Type, rowData) {
      if (Type === 'edit') {
        this.infopopup.id = rowData.log_id
        this.infopopup.username = rowData.username
        this.infopopup.deposit = rowData.topup
        this.infopopup.withdraw = rowData.withdraw
        this.infopopup.turn = rowData.turn
        this.infopopup.month = rowData.period
        this.showpopup = true
      } else {
        this.showpopup = true
      }
      this.infopopup.type = Type
    },
    async confirm_edit (type) {
      if (type === 'add') {
        await axios.post('/mvp/addmvp', {
          username: this.infopopup.username,
          topup: this.infopopup.deposit,
          withdraw: this.infopopup.withdraw,
          name: this.infopopup.name,
          surname : this.infopopup.surname,
          turn: this.infopopup.turn,
          period: this.infopopup.month
        })
          .then(response => (this.error = response.data))
      } else {
        await axios.post('/mvp/updatemvp', {
          username: this.infopopup.username,
          topup: this.infopopup.deposit,
          withdraw: this.infopopup.withdraw,
          turn: this.infopopup.turn,
          id: this.infopopup.id,
          period: this.infopopup.month
        })
          .then(response => (this.error = response.data))
      }

      if (this.error.status === true) {
        this.$vs.notify({
          time: 10000,
          color: 'success',
          position: 'top-right',
          icon: 'error',
          text: this.error.info,
          title : 'ทำรายการสำเร็จ'
        })
        this.callinfo()
        this.showpopup = false
      } else {
        this.$vs.notify({
          time: 10000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          text: this.error.info,
          title : 'ทำรายการผิดพลาด'
        })
        this.showpopup = false
      }
    },
    clearData () {
      this.infopopup.username = ''
      this.infopopup.deposit = ''
      this.infopopup.withdraw = ''
      this.infopopup.name = ''
      this.infopopup.surname = ''
      this.infopopup.turn = ''
      this.infopopup.month = ''
    }
  }
}

</script>

<style lang="scss">
  #profile-tabs {
    .vs-tabs--content {
      padding: 0;
    }
  }

</style>
